export const paths = {
    login: '/login/',
    notifications: '/notifications/',
    productLocations: '/locations/get_prd_locations/',
    qaProductLocations: '/qa/get_prd_locations/',
    dashboard: '/dashboard/',
    qaDashboard: '/qa/dashboard',
    addBulkProduct: '/myproducts/upload/',
    categories: '/category/',
    qaCategories: '/qa/category/',
    departments: '/departments/',
    qaDepartments: '/qa/departments/',
    locations: '/locations/',
    qaLocations: '/qa/locations/',
    sellers: '/seller/',
    competitorsLocations: '/locations/get_competitor_locations/',
    qaCompetitorsLocations: '/qa/get_competitor_locations/',
    myProducts: '/myproducts/',
    qaMyProducts: '/qa/myproducts/',
    clients: '/company/',
    clientsMarket: '/users/get_existing_count/',
    users: '/users/',
    setDefaultLocation: '/locations/set_default_loc/?loc_id=',
    changePassword: '/change_password/',
    groups: '/groups/',
    qaGroups: '/qa/groups/',
    marketPlaces: '/market_place/',
    qaMarketPlaces: '/qa/market_place/',
    resetPasswordLink: '/password/reset_password_link?user_id=',
    resetPassword: '/password/reset_password',
    groupRules: '/groups/group_settings/',
    modifyRules: '/settings/',
    deleteAllProducts: '/myproducts/delete_all/',
    brands: '/myproducts/brands/',
    qaBrands: '/qa/myproducts/brands/',
    keyValue: '/myproducts/set_is_key_value/?prd_id=',
    productDetails: '/product_list/',
    updateScore: '/competitors/update_prd_score/',
    competitors: '/competitors/',
    qaCompetitors: '/qa/competitors',
    compPrdLocSettings: '/locations/prd_settings_locations',
    qaCompPrdLocSettings: '/qa/prd_settings_locations/',
    refreshCompetitors: '/competitors/refresh',
    enableNotification: '/myproducts/enable_notification/?prd_id=',
    groupActions: '/groups/group_prd_action/',
    qaApprove: '/qa/myproducts/qa_approve',
    qaToggleApproval: '/qa/myproducts/qa_toggle',
    editCompetitor: '/qa/competitors/',
    refreshAll: '/competitors/refresh_all',
    refreshButton: '/competitors/refresh_button',
    forgotPassword: '/password/forgot_password?email=',
    signup: '/register',
    dashboardLocation: '/dashboard/locations',
    qaDashboardLocation: '/qa/dashboard/locations',
    productHistory: '/myproducts/producthistory/',
}