import { combineReducers } from '@reduxjs/toolkit';
import themeReducer from './toggleTheme.reducers';
import loginUserReducer from './loginUser.reducers';
import notificationsReducer from './notifications.reducers';
import productLocationsReducer from './productLocations.reducers';
import dashboardReducer from './dashboard.reducers';
import addBulkProductReducer from './addBulkProducts.reducers';
import categoriesReducer from './categories.reducers';
import departmentsReducer from './departments.reducers';
import locationsReducer from './locations.reducers';
import sellersReducer from './sellers.reducers';
import countriesReducer from './countries.reducers';
import addCategoryReducer from './addCategory.reducers';
import addDepartmentReducer from './addDepartment.reducers';
import addLocationReducer from './addLocation.reducers';
import addSellerReducer from './addSeller.reducers';
import addSingleProductReducer from './addSingleProduct.reducers';
import clientsReducer from './clients.reducers';
import currenciesReducer from './currencies.reducers';
import addClientReducer from './addClient.reducers';
import editClientReducer from './editClient.reducers';
import updateUserStatusReducer from './updateUserStatus.reducers';
import setDefaultLocationReducer from './setDefaultLocation.reducers';
import changePasswordReducer from './changePassword.reducers';
import groupsReducer from './groups.reducers';
import marketPlacesReducer from './marketPlaces.reducers';
import usersReducer from './users.reducers';
import addUserReducer from './addUser.reducers';
import editUserReducer from './editUser.reducers';
import sendPasswordLinkReducer from './sendPasswordLink.reducers';
import rulesReducer from './rules.reducers';
import addRuleReducer from './addRule.reducers';
import editRuleReducer from './editRule.reducers';
import deleteRuleReducer from './deleteRule.reducers';
import deleteAllProductsReducer from './deleteAllProducts.reducers';
import productsReducer from './getProducts.reducers';
import brandsReducer from './getBrands.reducers';
import productStringReducer from './productString.reducers';
import deleteSingleProductReducer from './deleteSingleProduct.reducers';
import editProductReducer from './editProduct.reducers';
import toggleKeyValueReducer from './toggleKeyValue.reducers';
import productDetailsReducer from './productDetails.reducers';
import updateScoreReducer from './updateScore.reducers';
import detailsPageFiltersReducer from './detailsPageFilters.reducers';
import addCompetitorReducer from './addCompetitor.reducer';
import refreshCompetitorReducer from './refreshCompetitor.reducers';
import updateProductReducer from './updateProduct.reducers';
import enableNotificationReducer from './enableNotification.reducers';
import addSettingsGroupReducer from './addSettingsGroup.reducers';
import editGroupSettingsReducer from './editGroupSettings.reducers';
import modifyGroupProductsReducer from './modifyGroupProducts.reducers';
import competitorsLocationsReducer from './compLocations.reducers';
import tokenExpirationReducer from './handleTokenExpiration.reducers';
import deleteSingleCompProductReducer from './deleteSingleCompProduct.reducers';
import approveProductsReducer from './approveProducts.reducers';
import toggleApprovalReducer from './toggleApproval.reducers';
import editCompetitorReducer from './editCompetitor.reducers';
import compPrdLocSettingsReducer from './compPrdLocSettings.reducers';
import sendResetPasswordLinkReducer from './resetPasswordLink.reducers';
import deleteGroupReducer from './deleteGroup.reducers';
import refreshBtnStatusReducer from './refreshBtnStatus.reducers';
import refreshAllReducer from './refreshAll.reducer';
import forgotPasswordReducer from './forgotPassword.reducers';
import signUpReducer from './signUp.reducers';
import dashboardlocationReducer from './dashboardLocation.reducers';
import productHistoryReducer from './productHistory.reducers';

const rootReducer = combineReducers({
    theme: themeReducer,
    auth: loginUserReducer,
    notifications: notificationsReducer,
    productLocations: productLocationsReducer,
    dashboard: dashboardReducer,
    addBulkProducts: addBulkProductReducer,
    categories: categoriesReducer,
    departments: departmentsReducer,
    locations: locationsReducer,
    sellers: sellersReducer,
    countries: countriesReducer,
    addCategory: addCategoryReducer,
    addDepartment: addDepartmentReducer,
    addLocation: addLocationReducer,
    addSeller: addSellerReducer,
    addSingleProduct: addSingleProductReducer,
    clients: clientsReducer,
    currencies: currenciesReducer,
    addClient: addClientReducer,
    editClient: editClientReducer,
    updateUserStatus: updateUserStatusReducer,
    setDefaultLocation: setDefaultLocationReducer,
    changePassword: changePasswordReducer,
    groups: groupsReducer,
    marketPlaces: marketPlacesReducer,
    users: usersReducer,
    addUser: addUserReducer,
    editUser: editUserReducer,
    sendPasswordLink: sendPasswordLinkReducer,
    rules: rulesReducer,
    addRule: addRuleReducer,
    editRule: editRuleReducer,
    deleteRule: deleteRuleReducer,
    deleteAllProducts: deleteAllProductsReducer,
    products: productsReducer,
    brands: brandsReducer,
    productString: productStringReducer,
    deleteSingleProduct: deleteSingleProductReducer,
    editProduct: editProductReducer,
    keyValue: toggleKeyValueReducer,
    productDetails: productDetailsReducer,
    updateScore: updateScoreReducer,
    detailsPageFilters: detailsPageFiltersReducer,
    addCompetitorDetails: addCompetitorReducer,
    refreshCompetitorDetails: refreshCompetitorReducer,
    updateProductDetails: updateProductReducer,
    enableNotificationDetails: enableNotificationReducer,
    addSettingsGroupDetails: addSettingsGroupReducer,
    editGroupSettingsDetails: editGroupSettingsReducer,
    groupActions: modifyGroupProductsReducer,
    competitorsLocations: competitorsLocationsReducer,
    tokenExpiration: tokenExpirationReducer,
    deleteSingleCompProductDetails: deleteSingleCompProductReducer,
    approveProducts: approveProductsReducer,
    toggleApproval: toggleApprovalReducer,
    editCompetitor: editCompetitorReducer,
    compPrdLocSettingsDetails: compPrdLocSettingsReducer,
    sendResetPasswordLinkDetails: sendResetPasswordLinkReducer,
    deleteGroupDetails: deleteGroupReducer,
    refreshBtnStatusDetails: refreshBtnStatusReducer,
    refreshAllDetails: refreshAllReducer,
    forgotPasswordDetails: forgotPasswordReducer,
    signUpDetails: signUpReducer,
    dashboardlocationDetails: dashboardlocationReducer,
    productHistoryDetails: productHistoryReducer
})
  
export default rootReducer;