import React from 'react';
import { Dialog, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import LineChart from '../Charts/LineChart';


const OttixInsightsDialog = ({dialogState, setDialogState, competitors}) => {

  const themeMode = useSelector(state => state.theme.mode);

  const handleCloseOttixInfoDialog = () => {
    setDialogState({ ...dialogState, openGraphInfo: false })
  };

  return (
    <Dialog
    open={dialogState.openGraphInfo} onClose={handleCloseOttixInfoDialog} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'
    PaperProps={{ style: { width: '90%', maxWidth: '90%', margin: 0, background: themeMode === 'dark' ? '#232D3B' : 'white', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A' } }}
    >
    <Grid container sx={{ overflowY: 'auto' }} className={`custom-scrollbar-${themeMode}`}>
        <Grid xs={12} item container sx={{display: 'flex', justifyContent: 'space-between', p: 1, pt: '20px', fontWeight: 'bold'}} >
            <Typography sx={{fontWeight: 'bold'}} >Ottix Insights</Typography>
            <IconButton onClick={handleCloseOttixInfoDialog} ><CloseIcon sx={{ cursor: 'pointer', color: themeMode === 'dark' ? '#E4E3E3' : '#5A5A5A', fontSize: '20px' }} /></IconButton>
        </Grid>
        <Grid item xs={12} ><Divider sx={{ backgroundColor: themeMode === 'dark' ? '#E4E3E3' : 'lighGrey' }} /></Grid>
        
        <Grid container style={{width:"100%",}}>
          <Grid item xs={12} style={{width:"100%",}} >
            <LineChart />
          </Grid>
        </Grid>
        
        <Grid xs={12} item container spacing={2} padding={2} >
            <Grid xs={6} item style={{ position: 'relative' }} sx={{ p: 4, height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}} >
            <div style={{ filter: 'blur(10px)', background: themeMode === 'dark' ? '#1a222d' : '#eaecee', boxShadow: 'none', height: '100%', width: '100%', position: 'absolute', top: 0, left: 0 }}></div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', boxShadow: 'none', zIndex: 1, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Typography sx={{ fontSize: '10px', textAlign: 'center', fontWeight: 'bold' }}>Coming Soon...</Typography>
              <Typography sx={{ fontSize: '13px', textAlign: 'center', fontWeight: 'bold' }}>Product Description Suggestion</Typography>
            </div>
            </Grid>
            <Grid xs={6} item style={{ position: 'relative' }} sx={{ p: 4, height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}} >
            <div style={{ filter: 'blur(10px)', background: themeMode === 'dark' ? '#1a222d' : '#eaecee', boxShadow: 'none', height: '100%', width: '100%', position: 'absolute', top: 0, left: 0 }}></div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', boxShadow: 'none', zIndex: 1, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <Typography sx={{ fontSize: '10px', textAlign: 'center', fontWeight: 'bold' }}>Coming Soon...</Typography>
              <Typography sx={{ fontSize: '13px', textAlign: 'center', fontWeight: 'bold' }}>AI Reviews</Typography>
            </div>
            </Grid>
        </Grid>

    </Grid>
  </Dialog>
  )
}

export default OttixInsightsDialog